import {
  APPROVE_BRANDAD_ERROR,
  APPROVE_BRANDAD_REQUEST,
  APPROVE_BRANDAD_SUCCESS,
  DECLINE_BRANDAD_ERROR,
  DECLINE_BRANDAD_REQUEST,
  DECLINE_BRANDAD_SUCCESS,
  GET_BRANDS_DASHBOARD_ERROR,
  GET_BRANDS_DASHBOARD_REQUEST,
  GET_BRANDS_DASHBOARD_SUCCESS,
  GET_BRANDS_ERROR,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRAND_ERROR,
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  NEW_BRAND_ERROR,
  NEW_BRAND_REQUEST,
  NEW_BRAND_SUCCESS,
  REFRESH_ADS_REQUEST,
  REMOVE_BRAND_ERROR,
  REMOVE_BRAND_REQUEST,
  REMOVE_BRAND_SUCCESS,
  UPDATE_BRAND_REQUEST,
  NEW_BENCHMARK_ERROR,
  NEW_BENCHMARK_REQUEST,
  NEW_BENCHMARK_SUCCESS,
  GET_RESULT_TYPES_ERROR,
  GET_RESULT_TYPES_REQUEST,
  GET_RESULT_TYPES_SUCCESS,
  UPDATE_BENCHMARK_REQUEST,
  UPDATE_BENCHMARK_SUCCESS,
  UPDATE_BENCHMARK_ERROR,
  REMOVE_BENCHMARK_REQUEST,
  REMOVE_BENCHMARK_SUCCESS,
  REMOVE_BENCHMARK_ERROR,
} from '../actions';

const INIT_STATE = {
  loading: false,
  data: [],
  error: '',
  selectedBrand: {},
  selectedBrandAds: {},
  resultTypes: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        total: undefined,
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: Array.isArray(action.payload.brands)
          ? [...action.payload.brands]
          : [...action.payload.brands.brands],
        total:
          !Array.isArray(action.payload.brands) && action.payload.brands.total,
        error: '',
      };
    case GET_BRANDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedBrand: action.payload.brand,
        error: '',
      };
    case GET_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REFRESH_ADS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_BRANDS_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_BRANDS_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedBrandAds: action.payload.brands,
        error: '',
      };
    case GET_BRANDS_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        selectedBrandAds: [],
        error: action.payload.error,
      };
    case UPDATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEW_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case REMOVE_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REMOVE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: [...action.payload.data],
      };
    case REMOVE_BRAND_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case APPROVE_BRANDAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case APPROVE_BRANDAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case APPROVE_BRANDAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DECLINE_BRANDAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case DECLINE_BRANDAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case DECLINE_BRANDAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEW_BENCHMARK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BENCHMARK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEW_BENCHMARK_SUCCESS:
      return {
        ...state,
        selectedBrand: {
          ...state.selectedBrand,
          benchmarks: [
            ...state.selectedBrand.benchmarks,
            { ...action.payload.data },
          ],
        },
        loading: false,
        error: '',
      };
    case UPDATE_BENCHMARK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BENCHMARK_SUCCESS:
      return {
        ...state,
        selectedBrand: {
          ...state.selectedBrand,
          benchmarks: state.selectedBrand.benchmarks.map((item) => {
            if (item.id === action.payload.benchmark.id) {
              return { ...item, ...action.payload.benchmark };
            }

            return item;
          }),
        },
        loading: false,
      };
    case UPDATE_BENCHMARK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_BENCHMARK_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case REMOVE_BENCHMARK_SUCCESS:
      return {
        ...state,
        selectedBrand: {
          ...state.selectedBrand,
          benchmarks: state.selectedBrand.benchmarks.filter((item) => {
            return item.id !== action.payload.benchmark.id;
          }),
        },
        loading: false,
        error: '',
      };
    case REMOVE_BENCHMARK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_RESULT_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case GET_RESULT_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case GET_RESULT_TYPES_SUCCESS:
      return {
        ...state,
        resultTypes: action.payload.resultTypes,
        loading: false,
        error: '',
      };
    default:
      return { ...state };
  }
};
