import {
  APPROVE_BRANDAD_ERROR,
  APPROVE_BRANDAD_REQUEST,
  APPROVE_BRANDAD_SUCCESS,
  DECLINE_BRANDAD_ERROR,
  DECLINE_BRANDAD_REQUEST,
  DECLINE_BRANDAD_SUCCESS,
  GET_BRANDS_DASHBOARD_ERROR,
  GET_BRANDS_DASHBOARD_REQUEST,
  GET_BRANDS_DASHBOARD_SUCCESS,
  GET_BRANDS_ERROR,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  GET_BRAND_ERROR,
  GET_BRAND_REQUEST,
  GET_BRAND_SUCCESS,
  GET_RESULT_TYPES_ERROR,
  GET_RESULT_TYPES_REQUEST,
  GET_RESULT_TYPES_SUCCESS,
  NEW_BENCHMARK_ERROR,
  NEW_BENCHMARK_REQUEST,
  NEW_BENCHMARK_SUCCESS,
  NEW_BRAND_ERROR,
  NEW_BRAND_REQUEST,
  NEW_BRAND_SUCCESS,
  REFRESH_ADS_REQUEST,
  REMOVE_BENCHMARK_ERROR,
  REMOVE_BENCHMARK_REQUEST,
  REMOVE_BENCHMARK_SUCCESS,
  REMOVE_BRAND_ERROR,
  REMOVE_BRAND_REQUEST,
  REMOVE_BRAND_SUCCESS,
  UPDATE_BENCHMARK_ERROR,
  UPDATE_BENCHMARK_REQUEST,
  UPDATE_BENCHMARK_SUCCESS,
  UPDATE_BRAND_ERROR,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from '../actions';

export const getBrandsRequest = (id, role, nextPageStart, isPagination) => ({
  type: GET_BRANDS_REQUEST,
  payload: { id, role, start: nextPageStart, isPagination },
});

export const getBrandsSuccess = (brands) => ({
  type: GET_BRANDS_SUCCESS,
  payload: { brands },
});

export const getBrandsError = (error) => ({
  type: GET_BRANDS_ERROR,
  payload: { error },
});

export const getBrandRequest = (id) => ({
  type: GET_BRAND_REQUEST,
  payload: { id },
});

export const getBrandSuccess = (brand) => ({
  type: GET_BRAND_SUCCESS,
  payload: { brand },
});

export const getBrandError = (error) => ({
  type: GET_BRAND_ERROR,
  payload: { error },
});

export const getBrandsDashboardRequest = (
  user,
  brand,
  start,
  limit,
  platform
) => ({
  type: GET_BRANDS_DASHBOARD_REQUEST,
  payload: { user, brand, start, limit, platform },
});

export const getBrandsDashboardSuccess = (brands) => ({
  type: GET_BRANDS_DASHBOARD_SUCCESS,
  payload: { brands },
});

export const getBrandsDashboardError = (error) => ({
  type: GET_BRANDS_DASHBOARD_ERROR,
  payload: { error },
});

export const updateBrandRequest = (id, body) => ({
  type: UPDATE_BRAND_REQUEST,
  payload: { id, body },
});

export const updateBrandSuccess = (brand) => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: { brand },
});

export const updateBrandError = (error) => ({
  type: UPDATE_BRAND_ERROR,
  payload: { error },
});

export const newBrandRequest = (body, history) => ({
  type: NEW_BRAND_REQUEST,
  payload: { body, history },
});

export const newBrandSuccess = (brand) => ({
  type: NEW_BRAND_SUCCESS,
  payload: { brand },
});

export const newBrandError = (error) => ({
  type: NEW_BRAND_ERROR,
  payload: { error },
});

export const removeBrandRequest = (id, setBrandsData, brandsDataCopy) => ({
  type: REMOVE_BRAND_REQUEST,
  payload: { id, setBrandsData, brandsDataCopy },
});

export const removeBrandError = (error) => ({
  type: REMOVE_BRAND_ERROR,
  payload: { error },
});

export const removeBrandSuccess = (data) => ({
  type: REMOVE_BRAND_SUCCESS,
  payload: { data },
});

export const approveBrandAdRequest = (
  body,
  setIsAdApproved,
  refreshBrandsDashboard,
  callback
) => ({
  type: APPROVE_BRANDAD_REQUEST,
  payload: { body, setIsAdApproved, refreshBrandsDashboard, callback },
});

export const approveBrandAdSuccess = () => ({
  type: APPROVE_BRANDAD_SUCCESS,
});

export const approveBrandAdError = (error) => ({
  type: APPROVE_BRANDAD_ERROR,
  payload: { error },
});

export const declineBrandAdRequest = (
  id,
  setIsAdDeclined,
  refreshBrandsDashboard
) => ({
  type: DECLINE_BRANDAD_REQUEST,
  payload: { id, setIsAdDeclined, refreshBrandsDashboard },
});

export const declineBrandAdSuccess = () => ({
  type: DECLINE_BRANDAD_SUCCESS,
});

export const declineBrandAdError = (error) => ({
  type: DECLINE_BRANDAD_ERROR,
  payload: { error },
});

export const refreshBrandDashboardAdsRequest = (user, brand, start, limit) => ({
  type: REFRESH_ADS_REQUEST,
  payload: { user, brand, start, limit },
});

// uses same success reducer
export const refreshBrandDashboardAdsSuccess = (brands) => ({
  type: GET_BRANDS_DASHBOARD_SUCCESS,
  payload: { brands },
});

// uses same error reducer
export const refreshBrandDashboardAdsError = (error) => ({
  type: GET_BRANDS_DASHBOARD_ERROR,
  payload: { error },
});

export const newBenchmarkRequest = (body, callback) => ({
  type: NEW_BENCHMARK_REQUEST,
  payload: { body, callback },
});

export const newBenchmarkSuccess = (data) => {
  return {
    type: NEW_BENCHMARK_SUCCESS,
    payload: { data },
  };
};

export const newBenchmarkError = (error) => ({
  type: NEW_BENCHMARK_ERROR,
  payload: { error },
});

export const updateBenchmarkRequest = (id, body, callback) => ({
  type: UPDATE_BENCHMARK_REQUEST,
  payload: { id, body, callback },
});

export const updateBenchmarkSuccess = (benchmark) => {
  return {
    type: UPDATE_BENCHMARK_SUCCESS,
    payload: { benchmark },
  };
};

export const updateBenchmarkError = (error) => ({
  type: UPDATE_BENCHMARK_ERROR,
  payload: { error },
});

export const removeBenchmakRequest = (id, callback) => ({
  type: REMOVE_BENCHMARK_REQUEST,
  payload: { id, callback },
});

export const removeBenchmarkError = (error) => ({
  type: REMOVE_BENCHMARK_ERROR,
  payload: { error },
});

export const removeBenchmarkSuccess = (benchmark) => ({
  type: REMOVE_BENCHMARK_SUCCESS,
  payload: { benchmark },
});

export const getResultTypesRequest = () => ({
  type: GET_RESULT_TYPES_REQUEST,
});

export const getResultTypesSuccess = (resultTypes) => ({
  type: GET_RESULT_TYPES_SUCCESS,
  payload: {
    resultTypes,
  },
});

export const getResultTypesError = (error) => ({
  type: GET_RESULT_TYPES_ERROR,
  payload: { error },
});
