import { UserRole } from 'helpers/authHelper';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { axiosAuth } from '../../helpers/useAuth';
import {
  APPROVE_BRANDAD_REQUEST,
  DECLINE_BRANDAD_REQUEST,
  GET_BRANDS_DASHBOARD_REQUEST,
  GET_BRANDS_REQUEST,
  GET_BRAND_REQUEST,
  NEW_BRAND_REQUEST,
  REFRESH_ADS_REQUEST,
  REMOVE_BRAND_REQUEST,
  UPDATE_BRAND_REQUEST,
  NEW_BENCHMARK_REQUEST,
  GET_RESULT_TYPES_REQUEST,
  UPDATE_BENCHMARK_REQUEST,
  REMOVE_BENCHMARK_REQUEST,
} from '../actions';
import {
  approveBrandAdError,
  approveBrandAdSuccess,
  declineBrandAdError,
  declineBrandAdSuccess,
  getBrandError,
  getBrandsDashboardError,
  getBrandsDashboardSuccess,
  getBrandsError,
  getBrandsSuccess,
  getBrandSuccess,
  newBrandError,
  refreshBrandDashboardAdsError,
  refreshBrandDashboardAdsSuccess,
  removeBrandError,
  updateBrandError,
  newBenchmarkSuccess,
  newBenchmarkError,
  getResultTypesSuccess,
  getResultTypesError,
  updateBenchmarkError,
  updateBenchmarkSuccess,
  removeBenchmarkError,
  removeBenchmarkSuccess,
} from './actions';

// ASYNC CALL
const getBrandsAsync = async (id, role, start, isPaging) => {
  const supBrandRequest = async () => {
    let url = `/getAllBrands`;
    if (isPaging) {
      url = url.concat(`?limit=20&start=${start}&pagination=1`);
    } else {
      url = url.concat(`?pagination=0`);
    }
    const { data } = await axiosAuth().get(url);
    return data;
  };
  try {
    if (role && role === UserRole.super_admin && start >= 0) {
      return supBrandRequest();
    } else if (role.type && role.type === UserRole.super_admin) {
      return supBrandRequest();
    } else {
      if (
        (role.type && role.type !== UserRole.super_admin) ||
        (role && role !== UserRole.super_admin)
      ) {
        const { data } = await axiosAuth().get(`/brands/start/${id}`);
        return data;
      }
    }
    // TODO -- change pathname to brands when BE schema changes
  } catch (error) {
    return error;
  }
};

// WORKER
function* getBrands({ payload }) {
  try {
    const brandsData = yield call(
      getBrandsAsync,
      payload.id,
      payload.role,
      payload.start,
      payload.isPagination
    );

    yield put(getBrandsSuccess(brandsData));
  } catch (error) {
    yield put(getBrandsError(error));
  }
}

// WATCHER
export function* watchGetBrands() {
  yield takeEvery(GET_BRANDS_REQUEST, getBrands);
}

// ASYNC CALL
const getBrandAsync = async (id) => {
  try {
    // TODO -- change pathname to brands when BE schema changes
    const { data } = await axiosAuth().get(`/brands/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* getBrand({ payload }) {
  try {
    const brandData = yield call(getBrandAsync, payload.id);
    yield put(getBrandSuccess(brandData));
  } catch (error) {
    yield put(getBrandError(error));
  }
}

// WATCHER
export function* watchGetBrand() {
  yield takeEvery(GET_BRAND_REQUEST, getBrand);
}

const getBrandsDashboardAsync = async (body) => {
  try {
    // TODO -- change pathname to brands when BE schema changes
    const postData = {
      user: body.user,
      start: body.start,
      limit: body.limit,
    };

    // since brand is not required
    // and start + limit have defaults
    // append brand if not null
    if (body.brand !== null) {
      postData.brand = body.brand;
    }

    // since platform is not required
    // and start + limit have defaults
    // append platform if not null
    if (body.platform !== null) {
      postData.platform = body.platform;
    }

    const { data } = await axiosAuth().post(`/brandads`, postData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* getBrandsDashboard({ payload }) {
  try {
    const brandsData = yield call(getBrandsDashboardAsync, payload);

    if (Object.keys(brandsData.ads).length > 0) {
      yield put(getBrandsDashboardSuccess({ ...brandsData }));
    } else {
      yield put(
        getBrandsDashboardError('There are no campaigns for this Brand.')
      );
    }
  } catch (error) {
    yield put(getBrandsDashboardError(error));
  }
}

// WATCHER
export function* watchGetBrandsDashboard() {
  yield takeEvery(GET_BRANDS_DASHBOARD_REQUEST, getBrandsDashboard);
}

const refreshAdsAsync = async (body) => {
  try {
    // TODO -- change pathname to brands when BE schema changes
    const postData = {
      user: body.user,
      start: body.start,
      limit: body.limit,
    };

    // since brand is not required
    // and start + limit have defaults
    // append brand if not null
    if (body.brand !== null) {
      postData.brand = body.brand;
    }

    const { data } = await axiosAuth().post(`/refreshads`, postData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* refreshAds({ payload }) {
  try {
    const brandsData = yield call(refreshAdsAsync, payload);
    if (Object.keys(brandsData).length > 0) {
      yield put(refreshBrandDashboardAdsSuccess({ ...brandsData }));
    } else {
      yield put(
        refreshBrandDashboardAdsError('There are no campaigns for this Brand.')
      );
    }
  } catch (error) {
    yield put(refreshBrandDashboardAdsError(error));
  }
}

// WATCHER
export function* watchRefreshAds() {
  yield takeEvery(REFRESH_ADS_REQUEST, refreshAds);
}

const uploadFileAsync = async (uploadData) => {
  try {
    const { data } = await axiosAuth().post(`/upload`, uploadData);
    return data;
  } catch (error) {
    return error;
  }
};

const updateBrandAsync = async ({ id, body }) => {
  try {
    // TODO -- change pathname to brands when BE schema changes
    const { data } = await axiosAuth().put(`/brands/${id}`, body);

    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* updateBrand({ payload }) {
  try {
    // if brand is updating profile pic
    if (payload.body.photo) {
      const uploadData = new FormData();
      uploadData.append('files', payload.body.photo);
      uploadData.append('refId', payload.id);
      uploadData.append('ref', 'user');
      uploadData.append('source', 'users-permissions');
      uploadData.append('field', 'photo');

      // upload request
      const imageData = yield call(uploadFileAsync, uploadData);
      // add returned form data to use in form submission
      payload.body.photo = imageData[0];
    }
    const brandData = yield call(updateBrandAsync, payload);
    yield put(getBrandSuccess(brandData));
  } catch (error) {
    yield put(updateBrandError(error));
  }
}

// WATCHER
export function* watchUpdateBrand() {
  yield takeEvery(UPDATE_BRAND_REQUEST, updateBrand);
}

const newBenchmarkAsync = async (body) => {
  try {
    const { data } = await axiosAuth().post(`/benchmarks/`, body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* newBenchmark({ payload: { body, callback } }) {
  try {
    const benchmarkData = yield call(newBenchmarkAsync, body);
    yield put(newBenchmarkSuccess(benchmarkData));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(newBenchmarkError(error));
  }
}

export function* watchNewBenchmark() {
  yield takeEvery(NEW_BENCHMARK_REQUEST, newBenchmark);
}

const updateBenchmarkAsync = async (id, body) => {
  try {
    const { data } = await axiosAuth().put(`/benchmarks/${id}`, body);
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* updateBenchmark({ payload: { id, body, callback } }) {
  try {
    const data = yield call(updateBenchmarkAsync, id, body);
    yield put(updateBenchmarkSuccess(data));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(updateBenchmarkError(error));
  }
}

// WATCHER
export function* watchUpdateBenchmark() {
  yield takeEvery(UPDATE_BENCHMARK_REQUEST, updateBenchmark);
}

const removeBenchmarkAsync = async (id) => {
  try {
    const { data } = await axiosAuth().delete(`/benchmarks/${id}`);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* removeBenchmark({ payload: { id, callback } }) {
  try {
    const data = yield call(removeBenchmarkAsync, id);
    yield put(removeBenchmarkSuccess(data));
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(removeBenchmarkError(error));
  }
}

// WATCHER
export function* watchRemoveBenchmark() {
  yield takeEvery(REMOVE_BENCHMARK_REQUEST, removeBenchmark);
}

const newBrandAsync = async (body) => {
  try {
    const { data } = await axiosAuth().post(`/brands/`, body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// ASYNC CALL
const getResultTypesAsync = async () => {
  try {
    const { data } = await axiosAuth().get('/result-types');
    return data;
  } catch (error) {
    return error;
  }
};

// WORKER
function* newBrand({ payload: { body, history } }) {
  try {
    const brandData = yield call(newBrandAsync, body);
    const resultTypes = yield call(getResultTypesAsync);

    yield put(getBrandSuccess(brandData));
    yield put(getResultTypesSuccess(resultTypes));

    // send to new brand details page
    history.replace(`/app/brands/${brandData.id}`);
    // history.replace({
    //   pathname: `/app/brands/${brandData.id}`,
    //   state: { brand: { result_types: resultTypes } },
    // });
  } catch (error) {
    yield put(newBrandError(error));
  }
}

// WATCHER
export function* watchNewBrand() {
  yield takeEvery(NEW_BRAND_REQUEST, newBrand);
}

const removeBrandAsync = async (id) => {
  try {
    const { data } = await axiosAuth().delete(`/brands/${id}`);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* removeBrand({ payload: { id, setBrandsData, brandsDataCopy } }) {
  try {
    yield call(removeBrandAsync, id);
  } catch (error) {
    yield put(removeBrandError(error));
    yield call(setBrandsData, brandsDataCopy);
  }
}

// WATCHER
export function* watchRemoveBrand() {
  yield takeEvery(REMOVE_BRAND_REQUEST, removeBrand);
}

const approveBrandAdAsync = async ({ body }) => {
  try {
    const { data } = await axiosAuth().post('/extendads/', body);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER //
function* approveBrandAd({
  payload: { body, setIsAdApproved, refreshBrandsDashboard, callback },
}) {
  try {
    const result = yield call(approveBrandAdAsync, { body });

    if (result) {
      yield call(setIsAdApproved, true);
      yield put(approveBrandAdSuccess());
      yield call(refreshBrandsDashboard);
    } else {
      yield put(
        approveBrandAdError({
          message: 'Ad cannot be approved at this time.',
        })
      );
    }
  } catch (error) {
    yield put(approveBrandAdError(error));
  }

  // Component side effects (toggle, etc.)
  yield call(callback);
}

// WATCHER
export function* watchApproveBrandAd() {
  yield takeEvery(APPROVE_BRANDAD_REQUEST, approveBrandAd);
}

const declineBrandAdAsync = async (id) => {
  try {
    const { data } = await axiosAuth().post(`/updaterequest/${id}`, {
      status: 'DECLINE',
      spend: null,
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// WORKER
function* declineBrandAd({
  payload: { id, setIsAdDeclined, refreshBrandsDashboard },
}) {
  try {
    const result = yield call(declineBrandAdAsync, id);

    if (result) {
      yield call(setIsAdDeclined, true);
      yield put(declineBrandAdSuccess());
      yield call(refreshBrandsDashboard);
    } else {
      yield put(
        declineBrandAdError({
          message: 'Ad cannot be declined at this time.',
        })
      );
    }
  } catch (error) {
    yield put(declineBrandAdError(error));
  }
}

// WATCHER
export function* watchDeclineBrandAd() {
  yield takeEvery(DECLINE_BRANDAD_REQUEST, declineBrandAd);
}

// WORKER
function* getResultTypes() {
  try {
    const data = yield call(getResultTypesAsync);
    yield put(getResultTypesSuccess(data));
  } catch (error) {
    yield put(getResultTypesError(error));
  }
}

// WATCHER
export function* watchGetResultTypes() {
  yield takeEvery(GET_RESULT_TYPES_REQUEST, getResultTypes);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBrands),
    fork(watchGetBrand),
    fork(watchGetBrandsDashboard),
    fork(watchRefreshAds),
    fork(watchUpdateBrand),
    fork(watchNewBrand),
    fork(watchRemoveBrand),
    fork(watchApproveBrandAd),
    fork(watchDeclineBrandAd),
    fork(watchNewBenchmark),
    fork(watchUpdateBenchmark),
    fork(watchRemoveBenchmark),
    fork(watchGetResultTypes),
  ]);
}
